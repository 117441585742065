import { render, staticRenderFns } from "./managementAccounts.vue?vue&type=template&id=50ad1c15&scoped=true"
import script from "./managementAccounts.vue?vue&type=script&lang=js"
export * from "./managementAccounts.vue?vue&type=script&lang=js"
import style0 from "./managementAccounts.vue?vue&type=style&index=0&id=50ad1c15&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50ad1c15",
  null
  
)

export default component.exports